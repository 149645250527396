
import {computed, defineComponent, nextTick, onMounted} from "vue";

import CurrentTariff from "@/components/my-components/main-page/CurrentTariff.vue";
import UserProfile from "@/components/my-components/main-page/UserProfile.vue";
import LastPaymentWidget from "@/components/my-components/main-page/payments/LastPayment.vue";

import {
    setCurrentPageBreadcrumbs,
    setCurrentPageTitle,
} from "@/core/helpers/breadcrumb";
import {useStore} from "vuex";

import PaymentMethods from "@/components/my-components/main-page/payments/PaymentMethods.vue";
import LastNews from "@/components/my-components/main-page/LastNews.vue";
import Credits from "@/components/my-components/main-page/Credits.vue";

import {Actions} from "@/store/enums/StoreEnums";
import {reinitializeComponents} from "@/core/plugins/keenthemes";

export default defineComponent({
    name: "overview",
    components: {
        CurrentTariff,
        UserProfile,
        LastPaymentWidget,
        PaymentMethods,
        LastNews,
    },
    setup() {
        const store = useStore();

        const userData = computed(() => {
            return store.getters.currentUser;
        });

        onMounted(() => {
            setCurrentPageBreadcrumbs("Основна", ["Profile"]);

            setCurrentPageTitle("Основна");

            nextTick(() => {
                reinitializeComponents();
            });
        });

        return {
            userData,
        };
    },
});
