
import {defineComponent, onMounted} from "vue";
import Quill from "quill/dist/quill.js";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
    name: "last-news",
    props: {
        widgetClasses: String,
        currentUser: {},
    },
    components: {
        // Dropdown2,
    },
    setup() {
        return {};
    },
});
