
import {defineComponent, onMounted} from "vue";

export default defineComponent({
    name: "last-payment",
    props: {
        widgetClasses: String,
        lastPaymentData: {},
    },
    components: {
        //Dropdown2,
    },
    setup() {
        onMounted(() => {
            const editorId = "kt_forms_widget_1_editor";

            // init editor
            const options = {
                modules: {
                    toolbar: {
                        container: "#kt_forms_widget_1_editor_toolbar",
                    },
                },
                theme: "snow",
            };

            // Init editor
            //new Quill("#" + editorId, options);
        });
    },
});
