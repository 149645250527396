import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06db7d43"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "row d-block g-5 g-xxl-8 my-5"
}
const _hoisted_2 = { class: "col-lg-12 mt-3 float-start float" }
const _hoisted_3 = { class: "col-lg-8 col-md-7 col-sm-12 mt-3 float-start" }
const _hoisted_4 = {
  key: 0,
  class: "col-lg-4 col-md-5 col-sm-12 mt-3 float-end"
}
const _hoisted_5 = { class: "col-lg-4 col-md-5 mt-3 mb-xl-1 float-end" }
const _hoisted_6 = { class: "col-lg-8 col-md-7 mt-3 float-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LastNews = _resolveComponent("LastNews")!
  const _component_UserProfile = _resolveComponent("UserProfile")!
  const _component_LastPaymentWidget = _resolveComponent("LastPaymentWidget")!
  const _component_CurrentTariff = _resolveComponent("CurrentTariff")!
  const _component_PaymentMethods = _resolveComponent("PaymentMethods")!

  return (_ctx.userData.user != null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_LastNews, { currentUser: _ctx.userData }, null, 8, ["currentUser"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_UserProfile, {
            "widget-classes": "mb-5 mb-xxl-8",
            currentUser: _ctx.userData
          }, null, 8, ["currentUser"])
        ]),
        (_ctx.userData.settings.last_payment != undefined)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_LastPaymentWidget, {
                "widget-classes": "mb-5 mb-xxl-8",
                lastPaymentData: _ctx.userData.settings.last_payment
              }, null, 8, ["lastPaymentData"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_CurrentTariff, {
            "widget-classes": "mb-5 mb-xxl-8",
            currentTariff: _ctx.userData.settings.tariff
          }, null, 8, ["currentTariff"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_PaymentMethods, { currentUser: _ctx.userData }, null, 8, ["currentUser"])
        ])
      ]))
    : _createCommentVNode("", true)
}