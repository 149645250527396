<template>
    <!--begin::Engage widget 1-->
    <div class="card mt-5" v-if="currentTariff != undefined">
        <!--begin::Card header-->
        <div class="card-header">
            <!--begin::Card title-->
            <div class="card-title m-0">
                <h3 class="fw-bolder m-0">Поточний тариф</h3>
            </div>
            <!--end::Card title-->
        </div>

        <div class="card-body d-flex flex-column flex-center">

            <!--begin::Heading-->
            <div class="mb-7 text-center">
                <!--begin::Title-->
                <h1 class="text-dark mb-5 fw-boldest">{{ currentTariff.description }}</h1>
                <!--end::Title-->

                <!--begin::Price-->
                <div class="text-center">
                    <span class="mb-2 text-primary">$</span>
                    <span
                        class="fs-3x fw-bolder text-primary"
                    >{{ currentTariff.fee }}</span
                    >
                    <span class="fs-7 fw-bold opacity-50"
                    >/ <span data-kt-element="period">мес</span></span
                    >
                </div>
                <!--end::Price-->
            </div>
            <!--end::Heading-->
            <!--begin::Features-->
            <div class="w-100 mb-1">




            </div>
            <!--end::Features-->
        </div>
        <!--end::Option-->
    </div>
    <!--end::Body-->
</template>

<script>
import {defineComponent, onMounted} from "vue";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default {
    props: {
        currentTariff: {},
    },
    data() {
        return {};
    },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#tariff-download-upload-speed-gauge {
    width: 100%;
    height: 200px;
}
</style>
