<template>
    <NoMoneyRequest
        :user_no_money_request="currentUser.user.user_no_money_request"
    ></NoMoneyRequest>

    <div
        class="card mb-5 mt-0"
        style="min-height: 500px;"
        :class="{ 'mt-7': currentUser.user.user_no_money_request, widgetClasses }"
    >
        <!--begin::Card header-->
        <div class="card-header">
            <!--begin::Card title-->
            <div class="card-title m-0">
                <h3 class="fw-bolder m-0">Деталі профілю</h3>
            </div>
            <!--end::Card title-->
            <!--begin::Action-->
            <router-link to="/settings" class="btn btn-primary align-self-center"
            >Редагувати параметри
            </router-link
            >
            <!--end::Action-->
        </div>
        <!--begin::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-9">
            <!--begin::Notice Пользовательская скидка-->

            <!--begin::Row-->
            <div class="row mb-7 mt-10">
                <!--begin::Label-->
                <label class="col-lg-4 fw-bold text-muted">ПІБ</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8">
                  <span class="fw-bolder fs-6 text-gray-800">{{
                          currentUser.settings.settings.name
                      }}</span>
                </div>
                <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-bold text-muted">Телефон
                    <Popper content="Телефон має бути активним"
                            hover
                            class="light-popper align-self-center"
                            placement="left">
                        <i class="fas fa-exclamation-circle ms-1 fs-7"></i>
                    </Popper>
                </label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 d-flex align-items-center">
                    <span class="fw-bolder fs-6 text-gray-800 me-2">{{
                            currentUser.settings.settings.phone
                        }}</span>
                    <span
                        class="badge badge-danger"
                        v-if="!currentUser.settings.settings.phone_confirmed"
                    >Неперевірений</span
                    >
                    <span
                        class="badge badge-success"
                        v-else-if="currentUser.settings.settings.phone_confirmed"
                    >Перевірений</span
                    >
                </div>
                <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-bold text-muted">Email</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8">
                    <a href="#" class="fw-bold fs-6 text-gray-800 text-hover-primary">{{
                            currentUser.user.email
                        }}</a>
                </div>
                <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-bold text-muted">
                    Адреса
                    <Popper content="Адреса проживання"
                            hover
                            class="light-popper align-self-center"
                            placement="left">
                        <i class="fas fa-exclamation-circle ms-1 fs-7"></i>
                    </Popper>
                </label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-gray-800">{{
                        currentUser.user.address
                    }}</span>
                </div>
                <!--end::Col-->
            </div>

            <div class="separator separator-dashed mb-5"></div>
            <!--begin::Info-->

            <div class="d-flex flex-wrap">


                <!--begin::Stat-->
                <div
                    class="border border-gray-300 border-dashed rounded flex-grow-1 py-3 px-4 me-6 mb-3"
                >
                    <!--begin::Number-->
                    <div class="d-flex align-items-center">

                        <div class="fs-2 fw-bolder">
                            <VueNumberAnimation
                                ref="total_requests_counter"
                                :startAmount="0"
                                :endAmount="currentUser.statistics.total_requests"
                                :duration="3"
                                :decimals="0"
                                :autoinit="true"
                            ></VueNumberAnimation>
                        </div>
                    </div>
                    <!--end::Number-->

                    <!--begin::Label-->
                    <div class="fw-bold fs-6 text-gray-400">всього заявок</div>
                    <!--end::Label-->
                </div>
                <!--end::Stat-->

                <!--begin::Stat-->
                <div
                    class="border border-gray-300 border-dashed rounded flex-grow-1 py-3 px-4 me-6 mb-3"
                >
                    <!--begin::Number-->
                    <div class="d-flex align-items-center">
                        <div class="fs-2 fw-bolder">
                            <VueNumberAnimation
                                ref="total_notes_counter"
                                :startAmount="0"
                                :endAmount="currentUser.statistics.total_notes"
                                :duration="3"
                                :decimals="0"
                                :autoinit="true"
                            ></VueNumberAnimation>
                        </div>
                    </div>
                    <!--end::Number-->

                    <!--begin::Label-->
                    <div class="fw-bold fs-6 text-gray-400">
                        всього відгуків
                    </div>
                    <!--end::Label-->
                </div>
                <!--end::Stat-->
            </div>
            <!--end::Stats-->
        </div>
        <!--end::Wrapper-->

    </div>
    <!--end::Stats-->

</template>

<script>

import {defineComponent, onMounted} from "vue";
import ExclamationNotice from "@/components/my-components/notices/exclamation-notice.vue";

import NoMoneyRequest from "@/components/my-components/no-money-request.vue";
import Popper from "vue3-popper";
import VueNumberAnimation from "vue3-autocounter";

export default defineComponent({
    name: "user-profile",
    props: {
        widgetClasses: String,
        currentUser: {},
    },
    components: {
        //ExclamationNotice,
        NoMoneyRequest,
        Popper,

        VueNumberAnimation,

        // Dropdown2,
    },
    setup() {
        onMounted(() => {
            const editorId = "kt_forms_widget_1_editor";

            // init editor
            const options = {
                modules: {
                    toolbar: {
                        container: "#kt_forms_widget_1_editor_toolbar",
                    },
                },
                theme: "snow",
            };

            // Init editor
            //new Quill("#" + editorId, options);
        });
    },
});
</script>
